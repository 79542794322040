<template>
  <div class="main-body" style="padding: 0">
    <div class="inner-wrapper">
      <div class="inner-main">
        <page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel"></page-header>
        <div class="inner-main-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-featured card-featured-primary mb-4">
                <div class="card-body">
                  <fieldset class="form-fieldset" style="margin-bottom: 0.2rem">
                    <legend>Producto</legend>
                    <div class="form-row">
                      <div class="form-group col-sm-3">
                        <label>Código</label>
                        <input
                          :class="{
                            'is-invalid': users.code == '' && errorsBackend.code,
                          }"
                          v-model="users.code"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template v-if="errorsBackend.code">
                          <span v-for="(error, index) in errorsBackend.code" class="form-group__error" :key="index">
                            <i class="fas fa-exclamation-circle"></i> {{ error }}
                          </span>
                        </template>
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Nombres</label>
                        <input
                          :class="{
                            'is-invalid': users.first_name == '' && errorsBackend.first_name,
                          }"
                          v-model="users.first_name"
                          type="text"
                          class="form-control form-control-sm todo-mayuscula"
                        />
                        <template v-if="errorsBackend.first_name">
                          <span v-for="(error, index) in errorsBackend.first_name" class="form-group__error" :key="index">
                            <i class="fas fa-exclamation-circle"></i> {{ error }}
                          </span>
                        </template>
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Apellido paterno</label>
                        <input
                          :class="{
                            'is-invalid': users.father_last_name == '' && errorsBackend.father_last_name,
                          }"
                          v-model="users.father_last_name"
                          type="text"
                          class="form-control form-control-sm todo-mayuscula"
                        />
                        <template v-if="errorsBackend.father_last_name">
                          <span v-for="(error, index) in errorsBackend.father_last_name" class="form-group__error" :key="index">
                            <i class="fas fa-exclamation-circle"></i> {{ error }}
                          </span>
                        </template>
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Apellido materno</label>
                        <input
                          :class="{
                            'is-invalid': users.mother_last_name == '' && errorsBackend.mother_last_name,
                          }"
                          v-model="users.mother_last_name"
                          type="text"
                          class="form-control form-control-sm todo-mayuscula"
                        />
                        <template v-if="errorsBackend.mother_last_name">
                          <span v-for="(error, index) in errorsBackend.mother_last_name" class="form-group__error" :key="index">
                            <i class="fas fa-exclamation-circle"></i> {{ error }}
                          </span>
                        </template>
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Cargo</label>
                        <select
                          :class="{
                            'is-invalid': users.charge_id == null && errorsBackend.charge_id,
                          }"
                          v-model="users.charge_id"
                          class="custom-select custom-select-sm"
                        >
                          <option :value="null">Seleccionar</option>
                          <option :value="item.id" v-for="(item, index) in charges" :key="index">{{ item.name }}</option>
                        </select>
                        <template v-if="errorsBackend.charge_id">
                          <span v-for="(error, index) in errorsBackend.charge_id" class="form-group__error" :key="index">
                            <i class="fas fa-exclamation-circle"></i> {{ error }}
                          </span>
                        </template>
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Empresa</label>
                        <select
                          :class="{
                            'is-invalid': users.business_id == null && errorsBackend.business_id,
                          }"
                          v-model="users.business_id"
                          class="custom-select custom-select-sm"
                        >
                          <option :value="null">Seleccionar</option>
                          <option :value="item.id" v-for="(item, index) in businesses" :key="index">{{ item.name }}</option>
                        </select>
                        <template v-if="errorsBackend.business_id">
                          <span v-for="(error, index) in errorsBackend.business_id" class="form-group__error" :key="index">
                            <i class="fas fa-exclamation-circle"></i> {{ error }}
                          </span>
                        </template>
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Celular corporativo</label>
                        <input
                          :class="{
                            'is-invalid': users.phone_corporate == '' && errorsBackend.phone_corporate,
                          }"
                          v-model="users.phone_corporate"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template v-if="errorsBackend.phone_corporate">
                          <span v-for="(error, index) in errorsBackend.phone_corporate" class="form-group__error" :key="index">
                            <i class="fas fa-exclamation-circle"></i> {{ error }}
                          </span>
                        </template>
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Correo corporativo</label>
                        <input
                          :class="{
                            'is-invalid': users.email == '' && errorsBackend.email,
                          }"
                          v-model="users.email"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template v-if="errorsBackend.email">
                          <span v-for="(error, index) in errorsBackend.email" class="form-group__error" :key="index">
                            <i class="fas fa-exclamation-circle"></i> {{ error }}
                          </span>
                        </template>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <!--- BOTONES FOOTER -->
            <div class="col-md-12 text-center">
              <button @click="mtd_save" class="btn btn-primary btn-lg">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Son window-->
    <modal-1 class="bg-black-trans" :id="dataModal1.config.id" :propConfig="dataModal1.config" @action-close="mtd_closeModal1()">
      <component
        :is="dataModal1.slot.component"
        slot="content-modal"
        :propInfo="dataModal1.slot"
        @onload="mtd_componentOnload"
        @success="mtd_componentSuccess"
        @error="mtd_componentError"
        @action-close="mtd_closeModal1()"
      ></component>
    </modal-1>
    <!--End son window-->
  </div>
</template>
<script>
  import { mapActions, mapGetters } from "vuex";
  export default {
    components: {},
    data() {
      return {
        charges: [],
        businesses: [],
        users: {
          code: "",
          first_name: "",
          father_last_name: "",
          mother_last_name: "",
          charge_id: null,
          business_id: null,
          phone_corporate: "",
          email: "",
        },
        dataModel: {
          name: "users-store", //AQUI EN INGLES SINGULAR
          alias: "Registrar",
          icon: "ri-file-copy-2-line",
        },
        page: {
          header: {
            menu: ["Usuarios", "Registrar"],
          },
          buttons: {
            create: false,
            import: false,
            export: false,
          },
        },
        errorsBackend: {},
        //** MODAL1 */
        dataModal1: {
          slot: {
            component: null,
            data: {
              row: null,
              buttonSubmit: null,
              method: null,
            },
          },
          config: {
            title: null,
            id: "c-modal-1__new-receipt",
            idHash: "#c-modal-1__new-receipt",
            size: null,
            scroll: true,
          },
        },
      };
    },
    computed: {
      ...mapGetters(["authentication__user", "url_api"]),
    },
    created() {
      this.getData();
    },
    methods: {
      ...mapActions(["get", "post"]),
      /************* METODOS MODAL */
      mtd_componentOnload: function (response) {
        // eslint-disable-next-line no-undef
        $(this.dataModal1.config.idHash).modal("show");
      },
      mtd_componentSuccess: function (response) {
        this.mtd_closeModal1();
      },

      mtd_componentError: function () {
        //
      },
      mtd_closeModal1: function () {
        // eslint-disable-next-line no-undef
        $(this.dataModal1.config.idHash).modal("hide");
        this.dataModal1.slot = {
          component: null,
          data: {
            row: null,
            buttonSubmit: null,
            method: null,
          },
        };
      },
      /****************************** METODOS PROPIOS DEL COMPONENTE */
      getData: function () {
        this.get({
          url: this.url_api + "/workers-requirements",
          authorization: true,
          token: localStorage.getItem("token"),
        })
          .then((response) => {
            this.charges = response.charges;
            this.businesses = response.businesses;
          })
          .catch((error) => {});
      },
      mtd_save: function () {
        this.post({
          url: this.url_api + "/workers",
          params: this.users,
          authorization: true,
          token: localStorage.getItem("token"),
        })
          .then((response) => {
            this.$alert("Usuario registrado correctamente.", "Mensaje", "success").then({});
            this.users = {
              code: "",
              first_name: "",
              father_last_name: "",
              mother_last_name: "",
              charge_id: null,
              business_id: null,
              phone_corporate: "",
              email: "",
            };
            this.errorsBackend = {};
          })
          .catch((error) => {
            this.errorsBackend = error;
            this.$alert("Error al registrar el usuario.", "Mensaje", "error").then({});
          });
      },
    },
  };
</script>
