var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-body",staticStyle:{"padding":"0"}},[_c('div',{staticClass:"inner-wrapper"},[_c('div',{staticClass:"inner-main"},[_c('page-header',{attrs:{"propButtons":_vm.page.buttons,"propConfig":_vm.page.header,"propModel":_vm.dataModel}}),_c('div',{staticClass:"inner-main-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card card-featured card-featured-primary mb-4"},[_c('div',{staticClass:"card-body"},[_c('fieldset',{staticClass:"form-fieldset",staticStyle:{"margin-bottom":"0.2rem"}},[_c('legend',[_vm._v("Producto")]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-sm-3"},[_c('label',[_vm._v("Código")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.users.code),expression:"users.code"}],staticClass:"form-control form-control-sm",class:{
                          'is-invalid': _vm.users.code == '' && _vm.errorsBackend.code,
                        },attrs:{"type":"text"},domProps:{"value":(_vm.users.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.users, "code", $event.target.value)}}}),(_vm.errorsBackend.code)?_vm._l((_vm.errorsBackend.code),function(error,index){return _c('span',{key:index,staticClass:"form-group__error"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2),_c('div',{staticClass:"form-group col-sm-3"},[_c('label',[_vm._v("Nombres")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.users.first_name),expression:"users.first_name"}],staticClass:"form-control form-control-sm todo-mayuscula",class:{
                          'is-invalid': _vm.users.first_name == '' && _vm.errorsBackend.first_name,
                        },attrs:{"type":"text"},domProps:{"value":(_vm.users.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.users, "first_name", $event.target.value)}}}),(_vm.errorsBackend.first_name)?_vm._l((_vm.errorsBackend.first_name),function(error,index){return _c('span',{key:index,staticClass:"form-group__error"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2),_c('div',{staticClass:"form-group col-sm-3"},[_c('label',[_vm._v("Apellido paterno")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.users.father_last_name),expression:"users.father_last_name"}],staticClass:"form-control form-control-sm todo-mayuscula",class:{
                          'is-invalid': _vm.users.father_last_name == '' && _vm.errorsBackend.father_last_name,
                        },attrs:{"type":"text"},domProps:{"value":(_vm.users.father_last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.users, "father_last_name", $event.target.value)}}}),(_vm.errorsBackend.father_last_name)?_vm._l((_vm.errorsBackend.father_last_name),function(error,index){return _c('span',{key:index,staticClass:"form-group__error"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2),_c('div',{staticClass:"form-group col-sm-3"},[_c('label',[_vm._v("Apellido materno")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.users.mother_last_name),expression:"users.mother_last_name"}],staticClass:"form-control form-control-sm todo-mayuscula",class:{
                          'is-invalid': _vm.users.mother_last_name == '' && _vm.errorsBackend.mother_last_name,
                        },attrs:{"type":"text"},domProps:{"value":(_vm.users.mother_last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.users, "mother_last_name", $event.target.value)}}}),(_vm.errorsBackend.mother_last_name)?_vm._l((_vm.errorsBackend.mother_last_name),function(error,index){return _c('span',{key:index,staticClass:"form-group__error"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2),_c('div',{staticClass:"form-group col-sm-3"},[_c('label',[_vm._v("Cargo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.users.charge_id),expression:"users.charge_id"}],staticClass:"custom-select custom-select-sm",class:{
                          'is-invalid': _vm.users.charge_id == null && _vm.errorsBackend.charge_id,
                        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.users, "charge_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":null}},[_vm._v("Seleccionar")]),_vm._l((_vm.charges),function(item,index){return _c('option',{key:index,domProps:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),(_vm.errorsBackend.charge_id)?_vm._l((_vm.errorsBackend.charge_id),function(error,index){return _c('span',{key:index,staticClass:"form-group__error"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2),_c('div',{staticClass:"form-group col-sm-3"},[_c('label',[_vm._v("Empresa")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.users.business_id),expression:"users.business_id"}],staticClass:"custom-select custom-select-sm",class:{
                          'is-invalid': _vm.users.business_id == null && _vm.errorsBackend.business_id,
                        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.users, "business_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":null}},[_vm._v("Seleccionar")]),_vm._l((_vm.businesses),function(item,index){return _c('option',{key:index,domProps:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),(_vm.errorsBackend.business_id)?_vm._l((_vm.errorsBackend.business_id),function(error,index){return _c('span',{key:index,staticClass:"form-group__error"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2),_c('div',{staticClass:"form-group col-sm-3"},[_c('label',[_vm._v("Celular corporativo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.users.phone_corporate),expression:"users.phone_corporate"}],staticClass:"form-control form-control-sm",class:{
                          'is-invalid': _vm.users.phone_corporate == '' && _vm.errorsBackend.phone_corporate,
                        },attrs:{"type":"text"},domProps:{"value":(_vm.users.phone_corporate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.users, "phone_corporate", $event.target.value)}}}),(_vm.errorsBackend.phone_corporate)?_vm._l((_vm.errorsBackend.phone_corporate),function(error,index){return _c('span',{key:index,staticClass:"form-group__error"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2),_c('div',{staticClass:"form-group col-sm-3"},[_c('label',[_vm._v("Correo corporativo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.users.email),expression:"users.email"}],staticClass:"form-control form-control-sm",class:{
                          'is-invalid': _vm.users.email == '' && _vm.errorsBackend.email,
                        },attrs:{"type":"text"},domProps:{"value":(_vm.users.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.users, "email", $event.target.value)}}}),(_vm.errorsBackend.email)?_vm._l((_vm.errorsBackend.email),function(error,index){return _c('span',{key:index,staticClass:"form-group__error"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2)])])])])]),_c('div',{staticClass:"col-md-12 text-center"},[_c('button',{staticClass:"btn btn-primary btn-lg",on:{"click":_vm.mtd_save}},[_vm._v("Guardar")])])])])],1)]),_c('modal-1',{staticClass:"bg-black-trans",attrs:{"id":_vm.dataModal1.config.id,"propConfig":_vm.dataModal1.config},on:{"action-close":function($event){return _vm.mtd_closeModal1()}}},[_c(_vm.dataModal1.slot.component,{tag:"component",attrs:{"slot":"content-modal","propInfo":_vm.dataModal1.slot},on:{"onload":_vm.mtd_componentOnload,"success":_vm.mtd_componentSuccess,"error":_vm.mtd_componentError,"action-close":function($event){return _vm.mtd_closeModal1()}},slot:"content-modal"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }